.snipcart-item-line {
  padding: 20px;
}
.snipcart-item-line__header {
  line-height: 65px;
  margin: 0;
}
.snipcart-item-line__title {
  margin: 0;
  font-size: 15px;
  line-height: 26px;
  display: table-cell;
  vertical-align: middle;
}
.snipcart-item-line__media--small {
  height: 65px;
}
.snipcart-cart-summary-fees__notice {
  text-align: center;
  margin-bottom: 16px;
}
.snipcart-discount-box__button:focus {
  border: 1px solid #d5c196;
}
.snipcart-inline-discount-btn {
  padding: 0 20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-left: 6px;
}
.snipcart-discount-box {
  background-color: transparent;
}
